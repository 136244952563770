import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import routerSagas from './Router';
import teamsSagas from './Teams';
import paymentsSagas from './Payments';
import adminSagas from './Admin';

export default function* rootSaga(getState) {
  yield all([authSagas(), routerSagas(), teamsSagas(),paymentsSagas(), adminSagas()]);
}
