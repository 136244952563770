import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNIN_USER_REFRESH,
  SIGNIN_USER_REFRESH_SUCCESS,
  INIT,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  AUTH_OPEN_DIALOG,
  AUTH_CLOSE_DIALOG,
  AUTH_CANCEL_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  REQUEST_FORGOT_PASSWORD
} from 'constants/ActionTypes';

export const init = () => {
  return {
    type: INIT
  };
};

export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};

export const userSignInRefresh = refresh => {
  return {
    type: SIGNIN_USER_REFRESH,
    payload: refresh
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};

export const getUserDetailsSuccess = user => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: user
  };
};

export const userSignInRefreshSuccess = authUser => {
  return {
    type: SIGNIN_USER_REFRESH_SUCCESS,
    payload: authUser
  };
};

export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const authOpenDialog = (dialogType, email, password) => {
  return {
    type: AUTH_OPEN_DIALOG,
    payload: { dialogType, email, password }
  };
};

export const authCloseDialog = () => {
  return {
    type: AUTH_CLOSE_DIALOG
  };
};

export const authCancelLoading = () => {
  return {
    type: AUTH_CANCEL_LOADING
  };
};

export const changePassword = (newPassword, oldPassword) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { newPassword, oldPassword }
  };
};

export const changePasswordSuccess = newPassword => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: newPassword
  };
};

export const requestForgotPassword = loginEmail => {
  return {
    type: REQUEST_FORGOT_PASSWORD,
    payload: loginEmail
  };
};

export const forgotPassword = (newPassword, resetToken) => {
  return {
    type: FORGOT_PASSWORD,
    payload: { newPassword, resetToken }
  };
};

export const forgotPasswordSuccess = newPassword => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: newPassword
  };
};
