import {
  GET_USER_TEAMS_SUCCESS,
  GET_TEAM_DETAIL,
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_DEVICES_SUCCESS,
  GET_DEVICE_BATCHES,
  GET_DEVICE_BATCHES_SUCCESS,
  GET_BATCH_READINGS,
  GET_BATCH_READINGS_SUCCESS,
  SET_CO2_GAUGE,
  SET_PRESSURE_GAUGE,
  SET_TEMPERATURE_GAUGE,
  SET_TEMPERATURE_F_GAUGE,
  GET_TEAM_USERS_SUCCESS,
  GET_TEAM_INVITES_SUCCESS,
  SET_INTERVAL,
  CLEAR_INTERVAL,
  SIGNOUT_USER,
  GET_CHECK_LICENSE,
  GET_CHECK_LICENSE_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  userTeams: [],
  currentTeam: {},
  teamDevices: [],
  deviceBatches: [],
  readingsAll: [],
  comments: [],
  readingsLast: {},
  initDevice: 0,
  initBatch: 0,
  selectedDevice: {},
  selectedBatch: {},
  co2Gauge: {},
  pressureGauge: {},
  temperatureGauge: {},
  temperatureFGauge: {},
  teamUsers: [],
  invites: [],
  interval: null,
  licenses: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHECK_LICENSE_SUCCESS: {
      return {
        ...state,
        licenses: action.payload
      };
    }
    case GET_USER_TEAMS_SUCCESS: {
      return {
        ...state,
        userTeams: action.payload
      };
    }
    case GET_TEAM_DETAIL: {
      return {
        ...state,
        currentTeam: {},
        teamDevices: [],
        deviceBatches: [],
        readingsAll: [],
        readingsLast: {},
        initDevice: action.payload.initDevice || 0,
        initBatch: action.payload.initBatch || 0,
        selectedDevice: {},
        selectedBatch: {}
      };
    }
    case GET_TEAM_DETAIL_SUCCESS: {
      return {
        ...state,
        currentTeam: action.payload
      };
    }
    case GET_TEAM_DEVICES_SUCCESS: {
      return {
        ...state,
        teamDevices: action.payload
      };
    }
    case GET_DEVICE_BATCHES: {
      return {
        ...state,
        selectedDevice: action.payload,
        deviceBatches: [],
        readingsAll: [],
        readingsLast: {}
      };
    }
    case GET_DEVICE_BATCHES_SUCCESS: {
      return {
        ...state,
        deviceBatches: action.payload
      };
    }
    case GET_BATCH_READINGS: {
      return {
        ...state,
        // readingsAll: [],
        // readingsLast: {},
        selectedBatch: action.payload
      };
    }
    case GET_BATCH_READINGS_SUCCESS: {
      return {
        ...state,
        readingsAll: action.payload.all,
        readingsLast: action.payload.last,
        comments: action.payload.comments
      };
    }
    case SET_CO2_GAUGE: {
      return {
        ...state,
        co2Gauge: action.payload
      };
    }
    case SET_PRESSURE_GAUGE: {
      return {
        ...state,
        pressureGauge: action.payload
      };
    }
    case SET_TEMPERATURE_GAUGE: {
      return {
        ...state,
        temperatureGauge: action.payload
      };
    }
    case SET_TEMPERATURE_F_GAUGE: {
      return {
        ...state,
        temperatureFGauge: action.payload
      };
    }
    case GET_TEAM_USERS_SUCCESS: {
      return {
        ...state,
        teamUsers: action.payload
      };
    }
    case GET_TEAM_INVITES_SUCCESS: {
      return {
        ...state,
        invites: action.payload
      };
    }
    case SET_INTERVAL: {
      if (state.interval) clearInterval(state.interval);
      return {
        ...state,
        interval: action.payload
      };
    }
    case CLEAR_INTERVAL: {
      if (state.interval) clearInterval(state.interval);
      return {
        ...state,
        interval: null
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
