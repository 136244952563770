import { store } from 'NextApp';
import { authOpenDialog } from 'appRedux/actions/Auth';

export const authBase = `${process.env.REACT_APP_API_AUTH}/api`;
export const coreBase = `${process.env.REACT_APP_API_CORE}/api`;

export const headers = {
  'Content-Type': 'application/json'
};

export const getAuthHeaders = () => ({ ...headers, authorization: 'Bearer ' + store.getState().auth.accessToken });

export const apiBuilder = async (url, config) => {
  
  let message = 'An error occurred';
  try {
    const res = await fetch(url, {...config, maxHeaderSize: 1000000 });
    if (res.ok) {
      try {
        return Promise.resolve(await res.json());
      } catch (e) {
        return Promise.resolve();
      }
    } else {
      if (res.status === 401) {
        window.location.reload(false); // FIXME Improve Behavior
      }
      const err = await res.json();
      if (res.status === 409) {
        message = err.message;
        if (err.code === 3102) {
          setTimeout(() => {
            store.dispatch(authOpenDialog('new', JSON.parse(config.body).email, JSON.parse(config.body).password));
          }, 500);
        }
      } else if (res.status === 400 && err.errors) {
        message = err.errors[Object.keys(err.errors)[0]][0];
      } else if (err.error) {
        message = err.error;
      }
      return Promise.reject({ message });
    }
  } catch (e) {
    return Promise.reject({ message });
  }
};

export * from './Auth';
export * from './Users';
export * from './Teams';
export * from './Payments';
export * from './Tariff';
export * from './Device';
export * from './Licences';
export * from './Batch';
