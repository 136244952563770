import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getAllPaymentsApi = () =>
  apiBuilder(`${coreBase}/securepayment/admin`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
export const getUserPaymentsApi = () =>
  apiBuilder(`${coreBase}/securepayment/all`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getPaymentDetailApi = paymentId =>
  apiBuilder(`${coreBase}/securepayment/${paymentId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

  /* this action go to payment and withdrawl money from submited card number. this action 
  should check before usage that not repeated. 
  */
  export const submitRecurringPaymentApi = paymentId =>
  apiBuilder(`${coreBase}/securepayment/${paymentId}/SubmitRecurring`, {
    method: 'POST',
    headers: getAuthHeaders()
  });

export const getPaymentAdminReportApi = (from, to) =>
  apiBuilder(`${coreBase}/securepayment/admin/report?from=${from}&to=${to}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
