import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getAllDevicesApi = () =>
    
  apiBuilder(`${coreBase}/device`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getTeamDevicesApi = teamId =>
  apiBuilder(`${coreBase}/device?teamId=${teamId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
 
export const getUnusedDevicesApi = () =>
  apiBuilder(`${coreBase}/device/unused`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
  export const getAllDeletedDeviceApi = () =>
  apiBuilder(`${coreBase}/device/DeletedList`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  export const restoreDeviceApi = deviceId =>
  
  apiBuilder(`${coreBase}/device/Restore/${deviceId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
export const getDeviceProductTypesApi = () =>
  apiBuilder(`${coreBase}/device/productType`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createDeviceApi = device =>
  apiBuilder(`${coreBase}/device`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(device)
  });

export const editDeviceApi = device =>
  apiBuilder(`${coreBase}/device/${device.uuid}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(device)
  });

export const deleteDeviceApi = deviceId =>
  apiBuilder(`${coreBase}/device/${deviceId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
