import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  GET_USER_TEAMS,
  GET_TEAM_DETAIL,
  GET_TEAM_DEVICES,
  GET_DEVICE_BATCHES,
  GET_BATCH_READINGS,
  GET_TEAM_USERS,
  GET_TEAM_INVITES,
  INVITE_TEAM_MEMBER,
  CANCEL_INVITE,
  KICK_USER_FROM_TEAM,
  GET_CHECK_LICENSE
} from 'constants/ActionTypes';
import {
  getUserTeamsSuccess,
  getTeamDetailSuccess,
  getTeamDevicesSuccess,
  getDeviceBatches,
  getDeviceBatchesSuccess,
  getBatchReadings,
  getBatchReadingsSuccess,
  getTeamUsers,
  getTeamUsersSuccess,
  getTeamInvites,
  getTeamInvitesSuccess,
  setIntervalAction,
  getCheckLicenseSuccess
} from '../actions/Teams';
import { showAuthMessage } from '../actions/Auth';
import { adminCloseDialog, adminCancelLoading } from '../actions/Admin';
import {
  getUserTeamsApi,
  getTeamDetailApi,
  getTeamDevicesApi,
  getDeviceBatchesApi,
  getBatchReadingsAllApi,
  getBatchReadingsLastApi,
  getTeamUsersApi,
  getTeamInvitesApi,
  inviteTeamMemberApi,
  cancelInviteApi,
  kickUserFromTeamApi,
  getCheckLicensesApi
} from '../../api';
import { store } from 'NextApp';

function* handleGetUserTeams() {
  try {
    const res = yield call(getUserTeamsApi);
    yield put(getUserTeamsSuccess(res.teams));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleCheckLicenses() {
  try {
    const res = yield call(getCheckLicensesApi);
    yield put(getCheckLicenseSuccess(res));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetTeamDetail({ payload }) {
  const { teamId } = payload;
  try {
    const res = yield call(getTeamDetailApi, teamId);
    yield put(getTeamDetailSuccess(res));
    if (res.isCurrentUserTeamOwner) {
      yield put(getTeamInvites(teamId));
    }
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetTeamDevices({ payload }) {
  const state = yield select();
  const { teams } = state;
  try {
    const res = yield call(getTeamDevicesApi, payload);
    yield put(getTeamDevicesSuccess(res));
    let index = teams.initDevice === 0 ? 0 : res.findIndex(device => device.uuid === teams.initDevice);
    if (index === -1) index = 0;
    if (res[index]) yield put(getDeviceBatches(res[index]));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetDeviceBatches({ payload }) {
  const { uuid } = payload;
  const state = yield select();
  const { teams } = state;
  try {
    
    const res = yield call(getDeviceBatchesApi, uuid);
    yield put(getDeviceBatchesSuccess(res));
    let index = teams.initBatch === 0 ? 0 : res.findIndex(batch => batch.uuid === teams.initBatch);
    if (index === -1) index = 0;
    if (res[index]) yield put(getBatchReadings(res[index]));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetBatchReadings({ payload }) {
  const state = yield select();
  const { teams } = state;
  const { uuid } = payload;
  try {
    const res = yield call(getBatchReadingsAllApi, uuid);
    const { all, last, comments } = res;
    // const last = yield call(getBatchReadingsLastApi, uuid);
    const state = yield select();
    const { teams } = state;
    teams.co2Gauge.update({ value: last && last.Co2Level ? last.Co2Level : 0 });
    teams.pressureGauge
      .update({
        value: last && last.TankPressure ? last.TankPressure : 0,
        highlights:
          last && last.TankPressure
            ? [{ from: last.TargetPressure, to: last.TargetPressure + 2, color: '#8BC34A' }]
            : []
      })
      .draw();
    teams.temperatureGauge.update({
      value: last && last.Temperature ? last.Temperature : -5,
      units: last && last.Temperature ? last.Temperature.toFixed(1) + ' °C' : '°C'
    });
    teams.temperatureFGauge.update({
      value: last && last.Temperature ? last.Temperature * 1.8 + 32 : 20,
      units: last && last.Temperature ? (last.Temperature * 1.8 + 32).toFixed(1) + ' F' : 'F'
    });
    // teams.temperatureGauge.value = last ? last.Temperature : 0;
    yield put(getBatchReadingsSuccess(all ? all : [], last ? last : {}, comments ? comments : []));

    // if (teams.interval) clearInterval(teams.interval);
    // const interval = setInterval(() => {
    //   store.dispatch(getBatchReadings(payload));
    // }, 2 * 60 * 1000); // 2 min
    // yield put(setIntervalAction(interval));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetTeamUsers({ payload }) {
  try {
    const res = yield call(getTeamUsersApi, payload);
    yield put(getTeamUsersSuccess(res.users ? res.users : []));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetTeamInvites({ payload }) {
  try {
    const res = yield call(getTeamInvitesApi, payload);
    yield put(getTeamInvitesSuccess(res.invites));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleInviteTeamMember({ payload }) {
  const { teamId, email } = payload;
  try {
    yield call(inviteTeamMemberApi, teamId, email);
    yield all([put(adminCloseDialog()), put(getTeamInvites(teamId)), put(getTeamUsers(teamId))]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* handleCancelInvite({ payload }) {
  const { teamId, inviteId } = payload;
  try {
    yield call(cancelInviteApi, inviteId);
    yield put(getTeamInvites(teamId));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* handleKickUserFromTeam({ payload }) {
  const { teamId, userId } = payload;
  try {
    yield call(kickUserFromTeamApi, teamId, userId);
    yield put(getTeamUsers(teamId));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* getCheckLicensesSaga() {
  yield takeEvery(GET_CHECK_LICENSE, handleCheckLicenses);
}
export function* getUserTeamsSaga() {
  yield takeEvery(GET_USER_TEAMS, handleGetUserTeams);
}

export function* getTeamDetailSaga() {
  yield takeEvery(GET_TEAM_DETAIL, handleGetTeamDetail);
}

export function* getTeamDevicesSaga() {
  yield takeEvery(GET_TEAM_DEVICES, handleGetTeamDevices);
}

export function* getDeviceBatchesSaga() {
  yield takeEvery(GET_DEVICE_BATCHES, handleGetDeviceBatches);
}

export function* getBatchReadingsSaga() {
  yield takeEvery(GET_BATCH_READINGS, handleGetBatchReadings);
}

export function* getTeamUsersSaga() {
  yield takeEvery(GET_TEAM_USERS, handleGetTeamUsers);
}

export function* getTeamInvitesSaga() {
  yield takeEvery(GET_TEAM_INVITES, handleGetTeamInvites);
}

export function* inviteTeamMemberSaga() {
  yield takeEvery(INVITE_TEAM_MEMBER, handleInviteTeamMember);
}

export function* cancelInviteSaga() {
  yield takeEvery(CANCEL_INVITE, handleCancelInvite);
}

export function* kickUserFromTeamSaga() {
  yield takeEvery(KICK_USER_FROM_TEAM, handleKickUserFromTeam);
}

export default function* rootSaga() {
  yield all([
    fork(getUserTeamsSaga),
    fork(getTeamDetailSaga),
    fork(getTeamDevicesSaga),
    fork(getDeviceBatchesSaga),
    fork(getBatchReadingsSaga),
    fork(getTeamUsersSaga),
    fork(getTeamInvitesSaga),
    fork(inviteTeamMemberSaga),
    fork(cancelInviteSaga),
    fork(kickUserFromTeamSaga),
    fork(getCheckLicensesSaga)
  ]);
}
